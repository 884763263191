<template>
  <div class="real-estate">
    <a v-if="!isTablet" class="button fixed-button" href="#contact-form"> Become a Site Host </a>
    <section class="hero">
      <h1>
        Become an <br />
        Electrify America site host
      </h1>
      <p>and join North America's largest open DC fast EV charging network</p>
      <section class="flex">
        <p>Largest public Ultra-Fast charging network in the U.S. &mdash; with speeds up to 350 kW</p>
        <p>Attract new customers and revenue opportunities</p>
      </section>
      <a href="#contact-form" class="button">request to host an Ultra-Fast charging station</a>
    </section>
    <section class="body text">
      <div class="">
        <div class="lede lede--reverse">
          <div class="column image">
            <img
              loading="lazy"
              alt="Electrify America charging station with person using their mobile phone to set up their charging session"
              src="@/assets/images/RealEstate/person-using-charger.png"
            />
          </div>
          <div class="column">
            <h2>Site host benefits</h2>
            <p>
              Becoming a site host allows you to attract new customers and puts you at the forefront of the continually
              changing consumer landscape.
            </p>
            <ul class="list-electric-charger">
              <li><strong>18.7 million </strong>EVs are projected to be on U.S. roads by 2030.</li>
              <li>
                <strong>81% </strong>of EV owners report that the availability of a charger makes them more loyal to a
                business.
              </li>
              <li><strong>Diversify </strong>amenities available at your retail property.</li>
              <li><strong>Monetize </strong>your property in previously untapped ways.</li>
              <li><strong>Capitalize </strong>on relationships with OEMs by driving EVs of all kinds to your site.</li>
            </ul>
          </div>
        </div>
        <div class="cards-container">
          <h2>Optimal site criteria</h2>
          <section class="l-three-col">
            <div class="card">
              <img
                loading="lazy"
                src="@/assets/images/RealEstate/electric-business.svg?external"
                alt="icon displaying business and car with electric bolt"
              />
              <h3>
                Retail <br />
                proximity
              </h3>
            </div>
            <div class="card">
              <img
                loading="lazy"
                src="@/assets/images/RealEstate/electric-pin.svg?external"
                alt="icon displaying electric bolt inside pin"
              />
              <h3>
                Parking <br />
                spaces
              </h3>
            </div>
            <div class="card">
              <img
                loading="lazy"
                src="@/assets/images/RealEstate/electric-plug.svg?external"
                alt="icon displaying outlet plug with electric bolt"
              />
              <h3>
                Power <br />
                proximity
              </h3>
            </div>
          </section>
        </div>
        <div class="lede">
          <div class="column image">
            <img
              loading="lazy"
              alt="Electrify America charging stations being installed"
              src="@/assets/images/RealEstate/construction.png"
            />
          </div>
          <div class="column">
            <h2>
              Installation and maintenance <br />
              costs
            </h2>
            <p>
              <strong>Electrify America pays 100% of the upfront capital investment</strong>
              for the design and installation of new charging stations, as well as maintenance and upkeep
              costs&mdash;and we're just a phone call away after contracts are signed and hardware is installed.
            </p>
          </div>
        </div>
        <div class="lede lede--reverse">
          <div class="column image map">
            <img
              loading="lazy"
              alt="Map displaying charger locations around the United States"
              src="@/assets/images/RealEstate/chargers-map.png"
            />
            <ul>
              <li>Current public DC fast charging stations as of Aug 2022</li>
              <li>Planned future public DC Fast Charging Stations as of 2026</li>
            </ul>
          </div>
          <div class="column">
            <h2>Electrify America electric vehicle charging stations</h2>
            <p>
              Electrify America is the largest open Ultra-Fast charging network in the U.S. — with speeds up to 350 kW.
            </p>
          </div>
        </div>
      </div>
    </section>
    <form id="contact-form" @submit.prevent="validate" ref="form">
      <section>
        <h2>Interested in hosting an Ultra-Fast charging station as part of the Electrify America family?</h2>
        <p>Let's get to work. Fill out the following form to contact us and start the conversation.</p>
      </section>
      <div class="form-wrap">
        <p aria-hidden="true" class="form-legend">An asterisk (<span>*</span>) indicates a required field</p>
        <div class="input-field">
          <label ref="firstname" for="first_name"
            >First Name <span class="require-mark" aria-hidden="true">*</span></label
          >
          <input id="first_name" maxlength="39" name="first_name" v-model="firstName" type="text" required />
        </div>
        <div class="input-field">
          <label ref="lastname" for="last_name">Last Name <span class="require-mark" aria-hidden="true">*</span></label>
          <input
            id="last_name"
            maxlength="39"
            name="last_name"
            type="text"
            pattern="^[A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-_]+(?: +[A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-_]+)*$"
            title="This field only accepts letters and special characters (no trailing spaces)"
            v-model="lastName"
            required
          />
        </div>
        <div class="input-field">
          <label ref="email" for="email">Business Email <span class="require-mark" aria-hidden="true">*</span></label>
          <input
            id="email"
            maxlength="80"
            name="email"
            type="text"
            v-model="email"
            pattern="[a-zA-Z0-9_@./#&+-]+(?:\.[a-zA-Z0-9_@./#&+-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])"
            title="Please enter the correct email address"
            required
          />
        </div>
        <div class="input-field">
          <label ref="companyname" for="company_name"
            >Company Name <span class="require-mark" aria-hidden="true">*</span></label
          >
          <input id="company_name" maxlength="200" name="company_name" type="text" v-model="company" required />
        </div>
        <div class="input-field">
          <label ref="title" for="title">Job Title <span class="require-mark" aria-hidden="true">*</span></label>
          <input id="title" maxlength="128" name="title" type="text" v-model="title" required />
        </div>
        <div class="input-field">
          <label for="phone">Phone Number <span class="require-mark" aria-hidden="true">*</span></label>
          <input
            id="phone"
            maxlength="10"
            name="phone"
            v-model="phone"
            type="text"
            pattern="^\d+$"
            title="This field only accepts numbers"
            required
          />
        </div>
        <div class="input-field">
          <label for="address">Property Address <span class="require-mark" aria-hidden="true">*</span></label>
          <input id="address" v-model="street" name="address" type="text" required />
        </div>

        <div class="input-field">
          <label for="country"
            >Country <span class="require-mark" aria-hidden="true">*</span>
            <select id="country" name="country" v-model="country" required>
              <option value="United States" selected>United States</option>
              <option value="Canada">Canada</option>
            </select>
          </label>
        </div>
        <div class="input-field">
          <label for="state_code"
            >{{ isUs ? 'State' : 'Province' }} <span class="require-mark" aria-hidden="true">*</span>
            <select id="state_code" name="state_code" :disabled="country === ''" v-model="state" required>
              <option value="" selected hidden>Select a {{ isUs ? 'State' : 'Province' }}</option>
              <option v-for="item in StatesProvinceList" :value="item.name" :key="item.abbreviation">
                {{ item.name }}
              </option>
            </select>
          </label>
        </div>
        <div class="input-field">
          <label for="city">City <span class="require-mark" aria-hidden="true">*</span></label>
          <input id="city" v-model="city" name="city" type="text" required />
        </div>
        <div class="input-field">
          <label for="zipCode">Zip/Postal Code <span class="require-mark" aria-hidden="true">*</span></label>
          <input
            id="zipCode"
            maxlength="10"
            name="zipCode"
            v-model="zipCode"
            type="text"
            pattern="^[a-zA-Z0-9\s]+(?:[a-zA-Z0-9-\s])+(?:[a-zA-Z0-9\s])$"
            title="This field only accepts numbers"
            required
          />
        </div>
        <div class="input-field">
          <label for="like_to">I Would Like To <span class="require-mark" aria-hidden="true">*</span></label>
          <select v-model="interestType" name="like_to" id="like_to" required>
            <option v-for="(item, index) in interestedInOptions" :value="item" :key="index">{{ item }}</option>
          </select>
        </div>
        <div class="input-field">
          <label for="num_properties"
            >Number of Properties <span class="require-mark" aria-hidden="true">*</span></label
          >
          <select v-model="numberProperties" name="num_properties" id="num_properties" required>
            <option v-for="(item, index) in numberPropertiesOptions" :value="item" :key="index">{{ item }}</option>
          </select>
        </div>
        <div class="input-field">
          <label for="property_type">Property Type <span class="require-mark" aria-hidden="true">*</span></label>
          <select v-model="useCase" name="property_type" id="property_type" required>
            <option v-for="(item, index) in useCaseOptions" :value="item" :key="index">{{ item }}</option>
          </select>
        </div>
        <div class="input-field">
          <label for="notes">Notes</label>
          <textarea v-model="notes" maxlength="1000" id="notes" name="notes"></textarea>
        </div>
        <div class="input-field checkbox">
          <label for="further-communication" class="checkbox">
            <input
              v-model="OptMeinFutureComm"
              id="further-communication"
              name="further-communication"
              type="checkbox"
              value="true"
            />
            I agree to receive further information from Electrify America*
          </label>
          <p class="form-question">*I am aware that I may unsubscribe to electronic communication at any time.</p>
          <p class="form-question">
            Click <router-link class="link" :to="{ name: 'privacy-en' }">here</router-link> for Electrify America's
            Privacy Statement.
          </p>
        </div>
        <input ref="submitButton" class="button button--submit" name="submit" type="submit" value="SUBMIT" />
        <p class="references">
          <sup>1</sup>https://www.eei.org/resourcesandmedia/energytalk/Pages/Issue-In-Depth-11-1-2018.html
        </p>
        <p class="references">
          <sup>2</sup>https://www.linkedin.com/pulse/do-ev-charging-stations-make-more-loyal-customers-survey-leavitt/
        </p>
      </div>
      <p v-show="serverError !== ''" class="server-error">
        Error:
        <br />
        {{ serverError }}
      </p>
    </form>
  </div>
</template>

<script>
import States from '@/assets/json/us_states.json';
import Provinces from '@/assets/json/ca_provinces.json';
import ApiService from '@/services/Api';

export default {
  name: 'real-estate',
  metaInfo: {
    title: 'Real Estate | Electrify America',
    meta: [
      {
        name: 'description',
        content: 'Become an Electrify America site host.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/real-estate/' }],
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      email: null,
      company: null,
      title: null,
      phone: null,
      city: null,
      street: null,
      state: null,
      StatesProvinceList: States,
      country: 'United States',
      zipCode: null,
      interestedInOptions: [
        'Host an EV Charging Station on My Property',
        'Own My Own EV Charging Network',
        'Request a New Charging Location',
        'Other',
      ],
      interestType: '',
      numberPropertiesOptions: ['1', '2 to 5', '5+'],
      numberProperties: '',
      useCaseOptions: [
        'Retail',
        'Restaurant',
        'Hospitality/Hotel',
        'Government',
        'Convenience',
        'Workplace',
        'Dealership',
        'Residential',
        'Mixed Use',
        'Other',
      ],
      useCase: '',
      timelineOptions: ['As soon as possible', '6 - 12 months', '12-24 months', 'No Preference'],
      notes: null,
      OptMeinFutureComm: null,
      serverError: '',
    };
  },
  computed: {
    isPhone() {
      return this.$resize && this.$mq.below(750);
    },
    isTablet() {
      return this.$resize && this.$mq.below(1200);
    },
    isUs() {
      return this.country === 'United States';
    },
  },
  methods: {
    async validate(e) {
      e.preventDefault();
      this.$refs.submitButton.disabled = true;
      this.serverError = '';
      try {
        const params = {
          site: 'ELAM',
          recaptchaToken: await this.recaptcha(),
          body: {
            FirstName: this.firstName,
            LastName: this.lastName,
            Email: this.email,
            CompanyName: this.company,
            Title: this.title,
            Phone: this.phone,
            City: this.city,
            Street: this.street,
            State: this.state,
            Country: this.country,
            Zipcode: this.zipCode,
            InterestType: this.interestType,
            NumberOfProperties: this.numberProperties,
            UseCase: this.useCase,
            Notes: this.notes,
            OptMeinFutureComm: this.OptMeinFutureComm,
            utm_medium: this.$route.query.utm_medium,
            utm_source: this.$route.query.utm_source,
          },
        };
        this.realEstatePut(params);
      } catch (error) {
        console.error(error);
        this.$refs.submitButton.disabled = false;
      }
    },

    realEstatePut(params) {
      const loader = this.$loading.show();
      this.$store.commit('setEmail', this.email);
      ApiService.init();
      ApiService.put('/v1/salesforce/sitehostcontactus', params)
        .then((res) => {
          if (res.status === 'success') {
            this.$router.push({ name: 'email-verification-en' });
          } else {
            this.serverError = res.errorMsg;
          }
          loader.hide();
          this.$refs.submitButton.disabled = false;
        })
        .catch((error) => {
          // Commenting out surfacing Salesforce errors to end users until we've made messaging more robust
          /*
          if (error.response.status === 500) {
            this.serverError = 'There was an error submitting the form, please try again';
          } else {
            this.serverError = error.response.data.errMsg;
          }
          */
          this.serverError = 'There was an error submitting the form, please try again';

          this.$refs.submitButton.disabled = false;
          loader.hide();
          console.error(error);
        });
    },
    async recaptcha() {
      try {
        // Wait for recaptcha loading
        await this.$recaptchaLoaded();
        // Execute reCAPTCHA with action "login" and return the token
        return await this.$recaptcha('login');
      } catch (e) {
        throw new Error('recaptcha login failed');
      }
    },
  },
  watch: {
    country(newCountry) {
      switch (newCountry) {
        case 'United States':
          this.StatesProvinceList = States;
          break;
        case 'Canada':
          this.StatesProvinceList = Provinces;
          break;
        default:
          this.StatesProvinceList = [];
      }
      this.state = '';
    },
  },
  mounted() {
    this.$recaptchaLoaded().then(() => {
      this.$recaptchaInstance.showBadge();
    });
  },
  beforeDestroy() {
    this.$recaptchaInstance.hideBadge();
  },
};
</script>

<style lang="scss" scoped>
.real-estate {
  img {
    width: 100%;
    height: 100%;
  }

  .button.fixed-button {
    border-radius: 8px 0 0 8px;
    border: $c-primary;
    right: 0;
    z-index: 2;
    top: 150px;
    position: fixed;
    max-width: 150px;
    font-size: 1rem;
    box-sizing: border-box;
    padding: 24px 20px 24px 24px;
    text-transform: none;
    &:hover {
      color: $c-primary;
    }
  }

  .container {
    overflow: auto;
    padding: 0 20px;
    max-width: 1200px;
    margin: 0 auto;
  }

  .flex {
    display: flex;
    @media (max-width: 900px) {
      flex-direction: column;
    }
  }

  .hero {
    background: url('~@/assets/images/RealEstate/realestate-hero.png') no-repeat;
    background-size: auto;
    background-position-x: center;
    height: 896px;
    color: $c-primary-background;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 64px;
    @media (max-width: 900px) {
      padding-left: 20px;
      padding-right: 20px;
      min-height: 800px;
    }

    h1 {
      font-size: 64px;
      font-weight: 200;
      line-height: 0.92;
      margin: 0;
      @media (max-width: 900px) {
        font-size: 48px;
      }
    }
    h1 + p {
      font-size: 2rem;
      font-weight: $fw-regular;
      line-height: 38px;
      margin-top: 9px;
      max-width: 570px;

      @media (max-width: 900px) {
        font-size: 18px;
        max-width: 320px;
      }
    }

    h1,
    p {
      text-shadow: 0px 0px 6px black, 0px 0px 12px black;
      color: $c-primary-background;
    }

    .button {
      font-size: 1rem;
      background: linear-gradient(90deg, rgba(53, 189, 209, 1) 50%, rgba(255, 255, 255, 1) 50%);
      color: $c-primary;
      border: none;
      background-position: 100% 0;
      background-size: 202% auto;
      transition: all 300ms ease;
      margin-top: 20px;
      padding: 21px 45px;
      &:hover,
      &:active {
        background-position: 0 0;
      }
      @media (max-width: 450px) {
        margin-top: 0px;
      }
    }
    .flex {
      padding-top: 348px;
      text-align: center;
      justify-content: flex-end;

      p {
        margin: 0 30px;
        max-width: 230px;
        font-weight: $fw-regular;
      }
      @media (max-width: 900px) {
        padding-bottom: 100px;
        padding-top: 200px;

        p {
          margin: 10px 0;
          max-width: 600px;
        }
      }
      @media (max-width: 450px) {
        padding-bottom: 50px;
        padding-top: 100px;
      }
    }
  }

  .body {
    margin: -60px 0 0 0;
    padding: 0;
  }
  .cards-container {
    text-align: center;
    max-width: 988px;
    margin: 0 auto;
    margin-bottom: 120px;
    h2 {
      margin: 0.83em 0;
    }
  }
  .card {
    background-color: $c-secondary-background;
    width: 307px;
    margin-right: 30px;
    border-radius: 8px;
    text-align: left;
    padding: 60px 0 40px 40px;
    img {
      height: auto;
      width: auto;
      padding-bottom: 40px;
    }
    h3 {
      border-top: 3px solid #35bdd1;
      padding-top: 20px;
      max-width: 180px;
      font-size: 25px;
      font-weight: 600;
    }
    &:last-child {
      margin-right: 0;
    }
    @media (max-width: 1030px) {
      margin: 30px auto;

      &:last-child {
        margin-right: auto;
      }
    }
  }

  .lede {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &:first-of-type {
      margin-bottom: 100px;
    }
    &:last-of-type {
      margin-bottom: 100px;
    }
    a {
      text-decoration: underline;
      font-weight: $fw-medium;
      cursor: pointer;
    }

    h2 {
      max-width: 370px;
      letter-spacing: 0.02em;
      margin-bottom: 1rem;
      padding: 0px 0 35px 0;
      &::after {
        content: '';
        width: 84px;
        border-top: 4px solid #35bdd1;
        display: block;
        border-radius: 5px;
        position: relative;
        top: 34px;
        @media (max-width: 950px) {
          top: 23px;
        }
      }
    }

    p {
      margin: 0;
      margin-bottom: 28px;
      width: 70%;
    }
    ul {
      padding: 0;
    }

    .list-electric-charger {
      li {
        list-style: none;
        background: url('~@/assets/images/RealEstate/electric-icon.svg?external') no-repeat;
        padding-left: 30px;
        line-height: 24px;
        margin: 10px 0;
        width: 70%;
      }
    }

    .column {
      display: flex;
      flex-direction: column;
      position: relative;
      padding-left: 5%;
      width: calc(30%);
      justify-content: center;
      &.image {
        width: calc(70%);
        padding: 0;
        align-items: flex-start;
      }
      &.map {
        background-color: $c-secondary-background;
        img {
          width: 80%;
        }
        align-items: center;
        text-align: center;
        position: relative;
        span {
          position: relative;
          width: 100%;
        }
        li {
          width: 100%;
          padding: 0 10px;
          box-sizing: border-box;
        }
        li:first-of-type {
          list-style: circle inside url('~@/assets/images/RealEstate/green-dot.svg?external');
        }
        li:last-of-type {
          list-style: circle inside url('~@/assets/images/RealEstate/blue-dot.svg?external');
        }
      }
    }

    &--reverse {
      flex-direction: row-reverse;
    }

    @media screen and (max-width: 950px) {
      align-items: center;
      flex-direction: column;
      margin-bottom: 100px;

      .column {
        width: calc(100vw - 30px);
        align-items: center;
        justify-content: center;
        padding-left: 0;

        p {
          width: calc(100vw - 30px);
          max-width: 400px;
        }
        h2 {
          margin: 0;
          padding-top: 35px;
          max-width: 400px;
          width: calc(100vw - 30px);
          &.short-title {
            width: calc(100vw - 30px);
          }
        }
        &.image {
          width: 100%;
        }
        ul {
          max-width: 400px;
        }
      }
    }
  }

  form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    h2 {
      max-width: 376px;
      padding-bottom: 59px;
      margin-top: 0;
    }

    section {
      width: 40%;
      max-width: 400px;
      margin-left: 100px;
      @media (max-width: 1200px) {
        margin-left: 0;
      }
      p {
        &::before {
          content: '';
          width: 84px;
          border-top: 4px solid #35bdd1;
          display: block;
          border-radius: 5px;
          position: relative;
          bottom: 10px;
        }
      }
    }
    .form-wrap {
      width: 60%;
      padding-bottom: 80px;
      padding-top: 22px;
      margin-left: 100px;
      @media (max-width: 1200px) {
        margin-left: 0;
      }
    }

    .form-question {
      color: $c-text;
      font-weight: normal;
      font-size: 16px;
      span {
        font-size: 16px;
      }
    }
    p.form-question:first-of-type {
      margin-top: 16px;
    }

    h2,
    p {
      color: $c-primary;
    }

    .input-field {
      margin-bottom: 29px;
      position: relative;
    }

    input,
    textarea,
    select {
      transition: 500ms ease;
      box-sizing: border-box;
      width: 600px;

      @media (max-width: 950px) {
        width: calc(100vw - 50px);
      }
    }

    input[type='submit'] {
      width: 150px;
      margin-bottom: 50px;
    }

    input[type='checkbox'] {
      width: 16px;
      height: 16px;
      margin-bottom: 10px;
    }

    .references {
      font-size: 10px;
      margin: 4px 0;
      letter-spacing: 1.61;
      sup {
        font-size: 6px;
      }
    }
    @media (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 0px;
      .form-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100vw - 50px);
      }
      section {
        max-width: 100%;
        width: calc(100vw - 30px);
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
          max-width: 600px;
          margin: 0;
        }
        p {
          text-align: left;
        }
      }
    }
  }
}
</style>
